import React from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'

const mainTheme = {
  color: {
    face: {
      main: '#157B79',
      dark_main: '#0F5F5E',
      contrast: '#900C0C',
      secondary: '#D1D4DD',
      light: '#FFFFFF'
    },

    text: {
      main: '#000000',
      contrast: '#900C0C',
      secondary: '#FFFFFF',
      third: '#157B79',
      dark: '#313131',
      light: '#FFFFFF',
      placeholder: '#C0C0C0'
    },
  },

  font: {
    family: {
      main: 'Cookie, sans-serif',
      secondary: 'Montserrat, sans-serif',
    },

    size: {
      xxs: '8px',
      xs: '10px',
      s: '12px',
      sm: '14px',
      m: '16px',
      xm: '20px',
      l: '24px',
      xl: '32px',
      xxl: '45px',
      xxml: '60px',
      xxxl: '50px',
      xxxml: '70px',
      xxxxml: '80px',
      xxxxl: '100px'
    },

    weight: {
      xxs: 100,
      xs: 200,
      s: 300,
      m: 400,
      l: 600,
      xl: 800,
    }
  }
}

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${props => props.theme.font.family.main};
    font-size: ${props => props.theme.font.size.m};
    color: ${props => props.theme.color.text.main};
  }

  ${props => Object.keys(props.theme.color.face).map(key => {
    return `.color-background-${key} { background-color: ${props.theme.color.face[key]}; }`
  })}

  ${props => Object.keys(props.theme.color.text).map(key => {
    return `.color-${key} { color: ${props.theme.color.text[key]} !important; }`
  })}

  ${props => Object.keys(props.theme.font.family).map(key => {
    return `.font-family-${key} { font-family: ${props.theme.font.family[key]}; }`
  })}

  ${props => Object.keys(props.theme.font.size).map(key => {
    return `.font-size-${key} { font-size: ${props.theme.font.size[key]}; }`
  })}

  ${props => Object.keys(props.theme.font.weight).map(key => {
    return `.font-weight-${key} { font-weight: ${props.theme.font.weight[key]}; }`
  })}

  a {
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }
`

const Theme = ({ children }) => (
  <ThemeProvider theme={mainTheme}>
    <GlobalStyle />
    {children}
  </ThemeProvider>
)

export default Theme